import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import "./Sidebar.css";

export default function Sidebar({ menuState, setMenuState }) {
  const MenuWrapper = styled.menu`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;

    a {
      flex: 100%;
    }
  `;

  const StyledLink = styled(motion.Link)`
    color: aqua;
    height: 120px;
  `;

  const SidebarItem = styled(motion.div)`
    flex: 100%;
    font-size: 32px;
    justify-content: flex-start;
    align-items: center;
    transition: 0.25 ease-in-out;
    background-color: rgba(255, 255, 255, 1);
    margin: 8px;
    padding: 32px 16px;
    border-radius: 14px;

    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    :hover {
      background-color: rgba(0, 0, 1, 0.1);
    }
  `;

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      boxShadow: "0px 0px 32px 0px rgba(0, 0, 0, 0.25)",
    },
    closed: {
      opacity: 0,
      x: "+100%",
      boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
    },
  };

  return (
    <div>
      <Overlay menuState={menuState} setMenuState={setMenuState} />
      <motion.div
        className="sidebar"
        initial="closed"
        animate={menuState ? "open" : "closed"}
        variants={variants}
        transition={{ ease: "easeInOut", duration: 0.2 }}
      >
        <MenuWrapper>
          <SidebarLink destination="/projects/">Projects</SidebarLink>
          <SidebarLink destination="https://soundcloud.com/nnoannoa/tracks" target="_blank">Music</SidebarLink>
          <SidebarLink destination="/about/">About</SidebarLink>
          <SidebarLink destination="/contact/">Contact</SidebarLink>
        </MenuWrapper>
      </motion.div>
    </div>
  );
}

export function SidebarLink({ destination, children, external, target }) {
  const Item = styled(motion.div)`
    flex: 100%;
    font-size: 32px;
    justify-content: flex-start;
    align-items: center;
    transition: 0.25 ease-in-out;
    background-color: rgba(255, 255, 255, 1);
    margin: 8px;
    padding: 32px 16px;
    border-radius: 14px;

    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    :hover {
      background-color: rgba(0, 0, 1, 0.1);
    }
  `;

  return (
    <Link to={destination} target={target}>
      <Item whileTap={{ scale: 0.95 }}>{children}</Item>
    </Link>
  );
}

export function Overlay({ menuState, setMenuState }) {
  const variants = {
    open: { opacity: 0.5, visibility: `visible` },
    closed: { opacity: 0, visibility: `hidden` },
  };

  return (
    <div>
      <AnimatePresence>
        {menuState && (
          <motion.div
            onClick={() => setMenuState(false)}
            key="modal"
            className="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
        {/* <motion.div className="overlay" onClick={() => setMenuState(false)} initial="closed" animate={menuState ? "open" : "closed"} variants={variants} exit="closed"/> */}
      </AnimatePresence>
    </div>
  );
}
