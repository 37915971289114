import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Cross as Hamburger } from "hamburger-react";
import "./header.css";

export default function Header({ menuState, setMenuState }) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.log(menuState);
    console.log("Hello");
  }, [menuState]);

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Link to="/">
          <Logo>Matthew Ortega</Logo>
        </Link>
        <Menu>
          <Hamburger
            label="Show menu"
            toggled={menuState}
            toggle={setMenuState}
          />

          <div className="menu-links">
            <Link to="/projects/">
              <p>Projects</p>
            </Link>
            {/* <Link to="/page-2/">
              <p>Teaching</p>
            </Link>
            <Link to="/blog/">
              <p>Blog</p>
            </Link> */}
            <a href="https://soundcloud.com/nnoannoa/tracks" target="_blank">
              <p>Music</p>
            </a>
            <Link to="/about/">
              <p>About</p>
            </Link>
            <Link to="/contact/">
              <p>Contact</p>
            </Link>
          </div>
        </Menu>
      </ContentWrapper>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
`;

const Logo = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: black !important;
  width: 100%;
`;

const Menu = styled.div`
  display: flex;

  a {
    color: black;
    text-decoration: none;
  }

  a :visited {
    color: black;
    text-decoration: none;
  }

  a :hover {
    font-weight: bold;
  }
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
